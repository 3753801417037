<template>
  <div class="draw-route-map">
    <LMap @ready="mapReady" style="l-map" ref="leafmap" >
      <LTileLayer :url="selectProviderUrl" :attribution="selectProviderAttribution"></LTileLayer>
      <LPolyline :lat-lngs="path" v-if="showPolylinePath"></LPolyline>
      <LControl :position="'bottomleft'">
        <div>
          <select class="form-control" style="width: 300px;" v-model="selectProviderIndex">
            <option v-for="(item, index) in providerList" :key="index" :value="index">{{ item.name }}</option>
          </select>
        </div>
      </LControl>
    </LMap>
  </div>
</template>

<script>
// import moment from 'moment';
import L from 'leaflet';
import { LMap, LTileLayer, LPolyline, LControl } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';

export default {
  name: 'DrawRouteMap',
  data() {
    return {
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      milestoneMarkers: [],
      path: [],
      markerRoute: null,
      milestoneIcon: null,

      selectProviderIndex: 0,
      providerList: [
        {
          name: 'OpenStreet.DE',
          url: 'https://tile.openstreetmap.de/{z}/{x}/{y}.png',
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        },
        {
          name: 'OpenStreet',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        },
        {
          name: 'Esri.WorldImagery',
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
        },
        {
          name: '魯地圖',
          url: 'https://tile.happyman.idv.tw/map/moi_osm/{z}/{x}/{y}.png',
          attribution: 'Leaflet | © Taiwan TOPO contributors',
        }
        // {
        //   name: 'Stadia Dark',
        //   url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
        //   attribution: '&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        // }
      ],
    };
  },
  components: {
    LMap,
    LTileLayer,
    LPolyline,
    LControl,
  },
  mounted() {
    // this.milestoneIcon = L.icon({
    //   iconUrl: 'https://maps.google.com/mapfiles/kml/paddle/grn-blank.png',
    //   // iconUrl: 'http://maps.google.com/mapfiles/kml/paddle/ylw-blank.png',
    //   // iconUrl: 'https://joyhike.s3.ap-northeast-3.amazonaws.com/location.svg',
    //   iconSize: [32, 32],
    //   iconAnchor: [16, 32],
    // });

    this.milestoneIcon = L.divIcon({className: 'swim-record-icon'});
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
  },
  watch: {
    records() {
      this.syncMark();
    },
  },
  computed: {
    userName() {
      return this.user.UserName;
    },
    userId() {
      return this.user.UserID;
    },
    userDeviceId() {
      return this.user.UserDeviceId;
    },
    recordsWithGPS() {
      return this.records.filter((item) => {
        return item.lat !== undefined;
      });
    },
    showPolylinePath() {
      return this.recordsWithGPS.length >= 2;
    },

    selectProvider() {
      return this.providerList[this.selectProviderIndex];
    },
    selectProviderUrl() {
      return this.selectProvider.url;
    },
    selectProviderAttribution() {
      return this.selectProvider.attribution;
    }
  },
  methods: {
    __buildPopup(lat, lng, t) {
      const tstr = moment(t).format('YYYY-MM-DD HH:mm:ss');
      const popup = L.popup({
        content: `<div class="popup-body">
            <label>AA01</label>
            <div class="popup-info">
              <div class="popup-info-title">
                時間
              </div>
              <div class="popup-info-content">
                <small>${tstr}</small>
              </div>
            </div>
            <div class="popup-info">
              <div class="popup-info-title">
                裝置ID
              </div>
              <div class="popup-info-content">
                ${this.userDeviceId}
              </div>
            </div>
            <div class="popup-info">
              <div class="popup-info-title">
                參賽者名稱
              </div>
              <div class="popup-info-content">
                ${this.userName}
              </div>
            </div>
            <div class="popup-info">
              <div class="popup-info-title">
                GPS位置
              </div>
              <div class="popup-info-content">
                (${lat.toFixed(4)}, ${lng.toFixed(4)})
              </div>
            </div>
          </div>`,
          className: 'popup-outer',
          closeButton: false,
      });
      return popup;
    },
    mapReady() {
      console.log('map ready');
      // const mapObject = this.$refs.leafmap.mapObject;
      // console.log(mapObject);
      this.initFitMap();
    },
    syncMark() {
      const copyRecords = [... this.recordsWithGPS];
      const copyMilestones = [... this.milestoneMarkers];
      this.milestoneMarkers.splice(0, this.milestoneMarkers.length);
      for (const r of copyRecords) {
        let found = false;
        for (let i = 0;i<copyMilestones.length;i++) {
          const m = copyMilestones[i];
          if (m.__id === r._id) {
            found = true;
            copyMilestones.splice(i, 1);
            this.milestoneMarkers.push(m);
            break;
          }
        }
        if (!found) {
          const m = moment(r.time);
          const tempMarker = L.marker([r.lat, r.lng], {
            icon: this.milestoneIcon,
            draggable: false,
            title: m.format('YYYY/MM/DD HH:mm:ss'),
          });
          const popup = this.__buildPopup(r.lat, r.lng, r.time);
          tempMarker.addTo(this.$refs.leafmap.mapObject).bindPopup(popup);
          tempMarker.__id = r._id;
          tempMarker.__record = r;
          this.milestoneMarkers.push(tempMarker);
        }
      }
      for (const m of copyMilestones) {
        m.remove();
      }
      this.redrawRoutePolyline();
    },
    redrawRoutePolyline() {
      if (this.markerRoute !== null) {
        this.markerRoute.remove();
      }
      this.path.splice(0, this.path.length);
      const copyRecord = [... this.recordsWithGPS];
      if (copyRecord.length > 1) {
        const sorted = copyRecord.sort((a, b) => {
          return a.time - b.time;
        });
        for (let i=0;i<sorted.length;i++) {
          const r = sorted[i];
          this.path.push([r.lat, r.lng]);
        }
        // this.polyline = L.polyline(path, {color: 'red'}).addTo(this.$refs.leafmap);
      }
    },
    initFitMap() {
      let leafBounds = L.latLngBounds();
      leafBounds.extend(L.latLng(23.87286802064959, 120.89304540918752));
      leafBounds.extend(L.latLng(23.872469323499047, 120.94575708100506));
      leafBounds.extend(L.latLng(23.830390941470384, 120.94411082994587));
      leafBounds.extend(L.latLng(23.830084983394382, 120.89519241937782));
      this.$refs.leafmap.mapObject.fitBounds(leafBounds);
    },
    mapFitRecords() {
      if (this.recordsWithGPS.length === 0) {
        this.initFitMap();
        return;
      }
      let leafBounds = L.latLngBounds();
      for (const r of this.recordsWithGPS) {
        leafBounds.extend(L.latLng(r.lat, r.lng));
      }
      this.$refs.leafmap.mapObject.fitBounds(leafBounds);
    },
  }
};
</script>

<style lang="scss">
  .swim-record-icon {
    width: 32px;
    height: 32px;
    background-color: rgb(110, 180, 141);
    border-radius: 50%;
    border: solid 1px rgb(190, 207, 198);
  }
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .draw-route-map {
    width: 100%;
    height: 100%;
  }

  ::v-deep .popup-outer .leaflet-popup-content-wrapper, ::v-deep .popup-outer .leaflet-popup-tip {
    background-color: #00A8B4;
  }

  ::v-deep .popup-body {
    color: #fff;
    label {
      font-size: 1.25rem;
    }

    .popup-info {
      display: flex;
      justify-content: space-between;
      row-gap: .5rem;
      margin-bottom: .5rem;
      // flex-wrap: wrap;
      .popup-info-title {
        flex: 0 0 auto;
      }
      .popup-info-content {
        flex: 1 1;
        text-align: right;
      }
    }
  }
</style>
