<template>
  <div class="swim-map">
    <div class="w-100 h-100">
      <DrawRouteMap :user="observationTarget" :records="records" ref="routeMap"></DrawRouteMap>
    </div>
    <div class="search-div">
      <div v-if="step === 0">
        <h4>搜尋參賽者</h4>
        <input type="search" class="form-control mb-2" v-model="inputSearchName" @keyup.enter="searchUser"/>
        <button class="btn d-block btn-info w-100" @click="searchUser" :disabled="searchBtnDisabled">搜尋</button>
      </div>

      <div v-if="step === 1" class="user-search-result">
        <h4>搜尋結果</h4>

        <p v-if="searchResultList.length === 0">
          沒有符合的參賽者
        </p>

        <ul v-if="searchResultList.length > 0">
          <li v-for="(item) in searchResultList" :key="item.serial" @click="setObservation(item)">
            <a href="#">{{ item.UserName }}({{ item.UserID }})【{{ item.UserDeviceId }}】</a>
          </li>
        </ul>

        <button class="btn btn-sm btn-secondary" @click="backToSearch">
          ←返回
        </button>
      </div>

      <div v-if="step === 2">
        <p class="text-secondary text-right"><small>更新時間：{{ lastUpdateTimeString }}</small></p>
        <RecordSummary
          :user="observationTarget"
          :records="records"
        ></RecordSummary>
        <button class="btn btn-sm btn-secondary" @click="backToSearch">
          ←返回
        </button>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import RecordSummary from '@/components/RecordSummary.vue';
import DrawRouteMap from '@/components/DrawRouteMap.vue';

export default {
  name: 'SwimMap',
  data() {
    return {
      step: 0,
      userList: [],
      searchResultList: [],
      inputSearchName: '',

      observationTarget: {},
      observationTimer: null,
      records: [],
      lastUpdateTime: 0,

    }
  },
  beforeDestroy() {
    this.stopObservationTimer();
  },
  computed: {
    latestRecordTime() {
      if (this.records.length === 0) {
        return 0;
      }
      return this.records[this.records.length -1].time;
    },
    lastUpdateTimeString() {
      const m = moment(this.lastUpdateTime);
      return m.format('YYYY-MM-DD HH:mm:ss');
    },
    searchBtnDisabled() {
      return this.inputSearchName.trim().length === 0 || !this.$store.getters.isGameRunning;
    },
  },
  components: {
    RecordSummary,
    DrawRouteMap,
  },
  async mounted() {
    await this.readUserList();
  },
  methods: {
    async readUserList() {
      const BUSYNAME = 'READUSERBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const users = await this.$store.dispatch('api/readUserListPromise');
        this.userList.splice(0, this.userList.length);
        for (const u of users) {
          this.userList.push(u);
        }
      } catch (error) {
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    async searchUser() {
      const q = this.inputSearchName.trim();
      if (q.length === 0) {
        return;
      }
      const res = this.userList.filter((user) => {
        if (user.UserID.indexOf(q) !== -1 || user.UserName.indexOf(q) !== -1 || user.UserDeviceId.indexOf(q) !== -1) {
          return true;
        }
        return false;
      });
      this.searchResultList.splice(0, this.searchResultList.length);
      for (let i=0;i<res.length;i++) {
        this.searchResultList.push(res[i]);
      }
      this.step = 1;
      // console.log(res);
    },
    async setObservation(user) {
      const deviceId = user.UserDeviceId;
      if (!deviceId || deviceId.trim().length === 0) {
        alert('未綁定裝置');
        return;
      }
      this.observationTarget = Object.assign({}, user);
      this.startObservationTimer();

      const BUSYNAME = 'READGPSBUSY';
      this.$store.dispatch('appendComponentBusy', BUSYNAME);
      try {
        const res = await this.$store.dispatch('api/searchRecordsByDeviceIdPromise', {deviceId, start: this.$store.getters.runningGameStartTime});
        const sorted = res.sort((a, b) => {
          return a.time - b.time;
        })
        this.records.splice(0, this.records.length);
        for (const r of sorted) {
          this.records.push(r);
        }
        this.lastUpdateTime = new Date().getTime();
        this.step = 2;
        this.$refs['routeMap'].mapFitRecords();
        // console.log(res);
      } catch (error) {
        console.error(error);
        alert(error.toString());
      } finally {
        this.$store.dispatch('clearComponentBusy', BUSYNAME);
      }
    },
    backToSearch() {
      this.step = 0;
      this.records.splice(0, this.records.length);
      this.stopObservationTimer();
      this.$refs['routeMap'].initFitMap();
    },
    stopObservationTimer() {
      if (this.observationTimer !== null) {
        clearInterval(this.observationTimer);
      }
    },
    startObservationTimer() {
      this.stopObservationTimer();
      const timer = setInterval(async () => {
        try {
          const deviceId = this.observationTarget.UserDeviceId;
          const res = await this.$store.dispatch('api/searchRecordsByDeviceIdPromise', {deviceId, start: this.latestRecordTime + 1});
          const sorted = res.sort((a, b) => {
            return a.time - b.time;
          })
          for (let i=0;i<sorted.length;i++) {
            this.records.push(sorted[i]);
          }
          this.lastUpdateTime = new Date().getTime();
        } catch (error) {
          console.error(error);
          alert(error.toString());
        }
      }, 10 * 1000);
      this.observationTimer = timer;
    },
  }
};
</script>

<style lang="scss" scoped>
  .swim-map {
    position: relative;
    width: 100%;
    height: 100%;
  }


  .search-div {
    position: absolute;
    right: 3rem;
    top: 1.5rem;
    width: 400px;
    border-radius: 1rem;
    background-color: rgba(215, 235, 232, 0.9);
    padding: 1rem 2rem;
    z-index: 99999;
    max-height: 80%;
    overflow-y: auto;
  }

  .user-search-result {
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin: 0;
        padding: 0;
        a {
          display: inline-block;
          padding: .25rem 1rem;
          margin-bottom: .5rem;
          color: #fff;
          background-color: var(--dark-orange);
          border-radius: .5rem;
          transition: background-color .4s ease;
          &:hover {
            text-decoration: none;
            background-color: var(--main-orange);
          }
        }
      }
    }
    
  }
</style>