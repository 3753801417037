<template>
  <div class="record-summary">
    <div class="user-info mb-3">
      <p><span class="font-weight-bold">{{ userId }} ： {{ userName }}</span><small class="text-secondary">({{ userDeviceId }})</small></p>
    </div>

    <div class="data-summary">
      <ul>
        <li>總資料點：{{ recordDataCount }}</li>
        <li>含GPS資訊的資料點數：{{ recordWithGPSCount }}</li>
        <li>總距離：{{ totalDistance }} m</li>
        <li>總時長：{{ totalDuration }} 秒</li>
        <li>平均泳速：{{ averageSpeed.toFixed(2) }} m/秒</li>
        <li>資料流失率：{{ ((dataLostRate) * 100).toFixed(2) }}% <small class="text-secondary">({{ recordWithGPSCount }} / {{ idleDataCount.toFixed(0) }})</small></li>
      </ul>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';

export default {
  name: 'RecordSummary',
  props: {
    user: {
      type: Object,
      required: true,
    },
    records: {
      type: Array,
      required: true,
    },
  },
  computed: {
    userName() {
      return this.user.UserName;
    },
    userId() {
      return this.user.UserID;
    },
    userDeviceId() {
      return this.user.UserDeviceId;
    },
    recordDataCount() {
      return this.records.length;
    },
    recordWithGPSCount() {
      const r = this.records.filter((item) => {
        return item.lat !== undefined;
      });
      return r.length;
    },
    totalDistance() {
      let dis = 0;
      let lat1 = null, lng1 = null;
      for (let i=0;i<this.records.length;i++) {
        const pos = this.records[i];
        if (pos.lat !== undefined) {
          if (lat1 !== null) {
            const d = this.calcCrow(lat1, lng1, pos.lat, pos.lng);
            dis += d;
          }
          lat1 = pos.lat;
          lng1 = pos.lng;
        }
      }

      return (dis * 1000).toFixed(2);
    },
    totalDuration() {
      if (this.records.length < 2) {
        return 0;
      }
      const dis = this.records[this.records.length - 1].time - this.records[0].time;
      return Math.floor(dis / 1000);
    },
    averageSpeed() {
      if (this.totalDuration > 0) {
        return this.totalDistance / this.totalDuration;
      }
      return 0;
    },
    idleDataCount() {
      return this.totalDuration / 10;
    },
    dataLostRate() {
      if (this.totalDuration === 0) {
        return 0;
      }
      const rate = 1 - Math.min(this.recordWithGPSCount / this.idleDataCount, 1);
      return rate;
    },
  },
  methods: {
    calcCrow(lat_1, lon1, lat_2, lon2) {
      const R = 6371; // km
      const dLat = this.toRad(lat_2-lat_1);
      const dLon = this.toRad(lon2-lon1);
      const lat1 = this.toRad(lat_1);
      const lat2 = this.toRad(lat_2);

      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      const d = R * c;
      return d;
    },
    toRad(Value) {
        return Value * Math.PI / 180;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .record-summary {
    .user-info {

    }
  }
</style>
